exports = module.exports = require("../../../../../../SDK/Sana.Commerce.WebApp/ClientApp/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__ImageWrapper_image-wrapper{position:relative;display:flex;justify-content:center;align-items:center;text-align:center;height:100%;min-height:inherit}.ImageSliderContentBlock__ImageWrapper_content-padding{padding:0 16%}.ImageSliderContentBlock__ImageWrapper_content-center{justify-content:center;text-align:center}.ImageSliderContentBlock__ImageWrapper_content-right{justify-content:flex-end;text-align:right}.ImageSliderContentBlock__ImageWrapper_content-left{justify-content:flex-start;text-align:left}", ""]);

// exports
exports.locals = {
	"image-wrapper": "ImageSliderContentBlock__ImageWrapper_image-wrapper",
	"imageWrapper": "ImageSliderContentBlock__ImageWrapper_image-wrapper",
	"content-padding": "ImageSliderContentBlock__ImageWrapper_content-padding",
	"contentPadding": "ImageSliderContentBlock__ImageWrapper_content-padding",
	"content-center": "ImageSliderContentBlock__ImageWrapper_content-center",
	"contentCenter": "ImageSliderContentBlock__ImageWrapper_content-center",
	"content-right": "ImageSliderContentBlock__ImageWrapper_content-right",
	"contentRight": "ImageSliderContentBlock__ImageWrapper_content-right",
	"content-left": "ImageSliderContentBlock__ImageWrapper_content-left",
	"contentLeft": "ImageSliderContentBlock__ImageWrapper_content-left"
};