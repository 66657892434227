exports = module.exports = require("../../../../../../../SDK/Sana.Commerce.WebApp/ClientApp/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__CustomPaging_dot-style{width:100%}", ""]);

// exports
exports.locals = {
	"dot-style": "ImageSliderContentBlock__CustomPaging_dot-style",
	"dotStyle": "ImageSliderContentBlock__CustomPaging_dot-style"
};