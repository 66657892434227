exports = module.exports = require("../../../../../../../../SDK/Sana.Commerce.WebApp/ClientApp/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__TitleVariant_text-wrapper *{color:inherit}", ""]);

// exports
exports.locals = {
	"text-wrapper": "ImageSliderContentBlock__TitleVariant_text-wrapper",
	"textWrapper": "ImageSliderContentBlock__TitleVariant_text-wrapper"
};