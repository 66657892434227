exports = module.exports = require("../../../../../SDK/Sana.Commerce.WebApp/ClientApp/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__ImageSlider_vd-min-height{position:absolute;height:26px!important;width:100%;background-color:var(--placeholderColor,#f7f7f7);z-index:-1}", ""]);

// exports
exports.locals = {
	"vd-min-height": "ImageSliderContentBlock__ImageSlider_vd-min-height",
	"vdMinHeight": "ImageSliderContentBlock__ImageSlider_vd-min-height"
};