exports = module.exports = require("../../../../../../../SDK/Sana.Commerce.WebApp/ClientApp/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__ImageContent_image-content{position:absolute;z-index:1}.ImageSliderContentBlock__ImageContent_content-padding{padding:0 16%}.ImageSliderContentBlock__ImageContent_relative{position:relative}", ""]);

// exports
exports.locals = {
	"image-content": "ImageSliderContentBlock__ImageContent_image-content",
	"imageContent": "ImageSliderContentBlock__ImageContent_image-content",
	"content-padding": "ImageSliderContentBlock__ImageContent_content-padding",
	"contentPadding": "ImageSliderContentBlock__ImageContent_content-padding",
	"relative": "ImageSliderContentBlock__ImageContent_relative"
};